.bg-white-theme {
  background-color: #EDF7FF;
}
.nav-tabs .nav-link {
  background: #f5f5f5;
  color: black;
}
.nav-tabs .nav-link.active {
  color: #035fb3 !important;
  background-color: #fff !important;
  border-bottom: 2px solid #035fb3 !important;
}
.row {
  padding: 10px;
}
.border-neglect {
  border-width: 0px;
}
.tab-content {
  border-width: 0px;
  padding-top: 10px;
  padding-left: 0px;
  padding-right: 0px;
}
.select-drop {
  border: 1px solid rgb(147, 147, 147);
  border-radius: 4px;
  padding: 4px;
}
.custom-btn {
  background: #fff;
  color: #007cb9;
  border: 2px solid #007cb9;
  border-radius: 3px;
  padding: 5px 10px;
  text-transform: uppercase;
}
.side-sheet {
  box-shadow: 2px 4px 8px #585858;
  padding: 0px;
}
.custom-grey-btn {
  background: #f2f2f2;
  color: #212121;
  border: 1px solid #c2c2c2;
  border-radius: 3px;
  padding: 5px 10px;
  font-size: small;
}
.picker {
  color: #b2b2b3;
  color: #e0dede;
}

th {
  background: #7367F0;
  color: #ffffff;
  padding: 0.5rem 1rem;
  border: 1px solid #dee2e6;
}
td {
  border: 1px solid #dee2e6;
}
td {
  /* background: #3498da;
  color: #ffffff; */
  padding: 0.5rem 1rem;
  border: 1px solid #dee2e6;
}


